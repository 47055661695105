let lastScrollTop;
let scrollingToElement;
const arrow = document.getElementById('arrow');
document.addEventListener("scroll", function () {
  const st = window.pageYOffset || document.documentElement.scrollTop;

  // console.log(document.getElementById('how').getBoundingClientRect().top);
  // console.log(st);

  // const pages = Array.from(document.getElementsByClassName('page'));

  // const pageHeight = pages.filter(p => p.getBoundingClientRect().top > 0)[0].getBoundingClientRect().height;
  // const n = 0; //padding
  const homeLinkOffset = document.getElementById('home').getBoundingClientRect().top - 40;
  const howLinkOffset = document.getElementById('how').getBoundingClientRect().top - 40;
  const servicesLinkOffset = document.getElementById('services').getBoundingClientRect().top - 40;
  const aboutLinkOffset = document.getElementById('about').getBoundingClientRect().top - 40;
  const pricingLinkOffset = document.getElementById('pricing').getBoundingClientRect().top - 40;
  const contactLinkOffset = document.getElementById('contact').getBoundingClientRect().top - 40;

  if (homeLinkOffset <= 0) {
    links.forEach(l => l.classList.remove('active'));
    homeLink.classList.add('active');
  }

  if (howLinkOffset <= 0) {
    links.forEach(l => l.classList.remove('active'));
    howLink.classList.add('active');
  }

  if (servicesLinkOffset <= 0) {
    links.forEach(l => l.classList.remove('active'));
    servicesLink.classList.add('active');
  }

  if (aboutLinkOffset <= 0) {
    links.forEach(l => l.classList.remove('active'));
    aboutLink.classList.add('active');
  }

  if (pricingLinkOffset <= 0) {
    links.forEach(l => l.classList.remove('active'));
    pricingLink.classList.add('active');
  }

  if (contactLinkOffset <= 0) {
    links.forEach(l => l.classList.remove('active'));
    contactLink.classList.add('active');
  }

  if (st > lastScrollTop) {
    arrow.style.transition = '0.6s';
    arrow.style.opacity = '0';
  } else {
    if (window.pageYOffset === 0) {
      arrow.style.transition = '0.6s';
      arrow.style.opacity = '1';
    }
  }

  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}, false);

arrow.addEventListener('click', () => {
  scrollToSection(document.getElementById('how'));
});

function scrollToSection(element) {
  window.scrollTo(0, element.offsetTop);
}
function offset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

function cumulativeOffset(element) {
  var top = 0, left = 0;
  do {
    top += element.offsetTop  || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while(element);

  return {
    top: top,
    left: left
  };
};


const homeLink = document.getElementById('home-link');
const howLink = document.getElementById('how-link');
const servicesLink = document.getElementById('services-link');
const aboutLink = document.getElementById('about-link');
const pricingLink = document.getElementById('pricing-link');
const contactLink = document.getElementById('contact-link');

const links = [homeLink, howLink, servicesLink, aboutLink, pricingLink, contactLink];

links.forEach(link => {
  link.addEventListener('click', () => {
    const pageAnchor = link.id.substring(0, link.id.indexOf('-'));
    console.log(pageAnchor);
    // links.forEach(l => l.classList.remove('active'));
    // link.classList.add('active');
    scrollToSection(document.getElementById(pageAnchor));
  });
});



// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; }
  }));
} catch(e) {}

let wheelOpt = supportsPassive ? { passive: false } : false;
let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

// disableScroll();
